.App {
  width: 100%;
  height: 98vh;
  max-height: 800px;
  max-height: -webkit-fill-available;
  display: flex;
  overflow-y: hidden;
  align-items: flex-start;
  justify-content: center;
}

.container {
  width: 300px;
  height: 98%;
  max-height: 630px;
  margin-top: 20px;
  border-top: 2px solid rgba(0, 0, 0, 0.024);
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
  font-family: "Segoe UI", "Helvetica Neue";
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.chat {
  display: flex;
  margin-bottom: auto;
  flex-flow: column;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  margin-top: 16px;
  width: 100%;
}

.textInput {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.contact-form {
  width: 100%;
  display: flex;
  position: relative;
}

.msgBox {
  box-sizing: border-box;
  max-width: 260px;
  width: 75vw;
}

.backdrop {
  opacity: 0.4;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: #002c55;
}

.bd_visible {
  display: block;
}

.bd_hidden {
  display: none;
}

.sender {
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
  background: none;
}
.receiver {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-self: right;
  align-self: flex-end;
}

.receiver:nth-last-child(2) .outgoing {
  border-top-right-radius: 6px;
  margin-top: 2px;
}

.receiver:nth-last-child(3) .outgoing {
  border-bottom-right-radius: 6px;
  margin-bottom: 0px;
}

p {
  padding: 0;
  margin: 0;
}

.typing {
  height: 200px;
}
.sendMsgBtn {
  width: 100%;
}

a {
  color: white;
  text-decoration: none;
}

.profile {
  border-radius: 100%;
  width: 24px;
  margin: 0 0 8px 6px;
  opacity: 0.6;
}

.social {
  display: flex;
  box-sizing: border-box;
  margin: 10px 0 10px 0;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
}
.socialIcon {
  width: 40px;
  height: 40px;
  margin: 0 16px;
}
.socialIcon img {
  width: 100%;
  border-radius: 8px;
}

.divider {
  height: 2px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.inputBg {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  height: 100%;
  width: 100%;
}

.write {
  background-color: inherit;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  resize: none;
  font-family: "Segoe UI";
}

.send {
  font-size: 18px;
  position: absolute;
  top: 54%;
  right: 45px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.sendIcon {
  color: rgb(50, 50, 50);
  background-color: none;
}

.delivered {
  height: 100%;
  color: #0084ff;
  margin-left: 8px;
  fill: #0084ff;
}

.write:focus {
  outline: none;
  overflow-wrap: normal;
}

.gif {
  box-sizing: border-box;
  margin-right: 16px;
  margin-bottom: -2px;
  margin-top: 4px;
  width: 75vw;
  max-width: 260px;
  border-radius: 30px;
}
.incoming {
  border-radius: 30px;
  background-color: #f1f0f0;
  padding: 8px 20px;
  margin: 4px 2px;
}
.outgoing {
  color: white;
  border-radius: 30px;
  background-color: #0084ff;
  padding: 8px 20px;
  margin: 4px 16px;
}

@media (min-width: 360px) {
  .container {
    width: 350px;
  }
}
@media (min-width: 1024px) {
  .App {
    align-items: center;
  }
  .container {
    max-height: 720px;
  }
  .social {
    margin: 20px 0 10px 0;
  }
}
