.slideUp {
  padding-top: 36px;
  width: 100%;
  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  transform: translateY(0);
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #0084ff;
  z-index: 101;
}
.close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  border: 2px solid #0084ff;
}
.closed {
  transform: translateY(320px);
  opacity: 0;
}
